import React from "react";
import _get from "lodash.get";

import GalleryItem from "app/components/GalleryItem";
import GalleryItemImage from "app/components/GalleryItemImage";
import GalleryList from "app/components/GalleryList";
import GalleryModalList from "app/components/GalleryModalList";

import useGalleryData from "app/hooks/useGalleryData";

const Gallery = ({ id }) => {
  const entry = useGalleryData(id);

  return (
    <GalleryModalList
      List={<GalleryList {...entry} />}
      label={"Back to Gallery"}
      gallery={_get(entry, "gallery")}
      ModalImage={<GalleryItemImage />}
      ModalContent={<GalleryItem />}
    />
  );
};

export default Gallery;
