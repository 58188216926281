import React from "react";
import _get from "lodash.get";
import { AnimatePresence, motion } from "framer-motion";
import { Global } from "@emotion/react";
import { useMeasure } from "react-use";

import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";

import { blur } from "app/components/Button";
import GalleryImage from "app/components/GalleryImage";
import Typography from "app/components/Typography";

import useGalleryItemData from "app/hooks/useGalleryItemData";
import useIntersection from "app/hooks/useIntersection";

import * as S from "app/components/GalleryList.style";

const blockNodes = ["p", "h1", "h2", "h3", "h4", "h5", "h6", "div", "ol", "li"];
const removeBlocks = (node, index) => {
  if (node.type === "tag" && blockNodes.includes(node.name)) {
    node.name = "span";
  }
  return convertNodeToElement(node, index, removeBlocks);
};

const GalleryListImage = ({ onClick, idx, perRow, id }) => {
  const entry = useGalleryItemData(id);

  const { ref, isIntersecting } = useIntersection({
    root: null,
    rootMargin: "80px",
    threshold: [0, 0.02, 0.99, 1],
  });

  return (
    <div ref={ref} css={[S.imageBox]}>
      <AnimatePresence>
        {isIntersecting && (
          <motion.button
            key={`gallery-thumb-${idx}`}
            data-track-name={"gallery-item-button"}
            css={[S.buttonBox, S.flexCenterAll, S.relative]}
            onClick={onClick}
            onMouseDown={blur}
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{
              ease: "easeIn",
              delay: Math.floor(idx % perRow) * 0.1,
            }}
          >
            <GalleryImage image={_get(entry, "image")} size={165} />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

const GalleryList = ({
  images,
  title,
  main,
  subheading,
  setActiveId,
  fullWidth,
}) => {
  const [ref, { width }] = useMeasure();

  return (
    <div css={[S.contentBox, fullWidth && S.fullWidth]}>
      <Global styles={S.galleryGlobal} />
      <div css={[S.gutters, S.innerBox]}>
        <section>
          <Typography variant="h1" css={S.pageTitle}>
            {title}
          </Typography>
          {main && (
            <Typography variant="h2" css={S.subTitle}>
              {ReactHtmlParser(main, {
                decodeEntities: true,
                transform: removeBlocks,
              })}
            </Typography>
          )}
          {subheading && <p css={S.sectionTitle}>{subheading}</p>}
        </section>
        <div css={S.galleryGrid} ref={ref}>
          {images.map(({ id }, idx) => (
            <GalleryListImage
              key={id}
              id={id}
              idx={idx}
              perRow={Math.floor(width / 195)}
              onClick={() => setActiveId(id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

GalleryList.defaultProps = {
  images: [],
};

export default GalleryList;
