import { useDeepCompareEffect } from "react-use";
import _get from "lodash.get";

import useSitePages from "app/hooks/useSitePages";
import useNavData from "app/hooks/useNav";

const formatNavItem = ({ baseUri, subpages, uri, ...item }) => ({
  ...item,
  uri: `${baseUri}/${uri}`,
  subpages: subpages
    ? formatGallery(subpages.map((subpage) => ({ ...subpage, baseUri })))
    : undefined,
});

const formatGallery = (gallery, baseUri) => {
  if (!Array.isArray(gallery)) {
    return gallery;
  }

  return gallery
    .map(({ context, ...item }) => ({ ...item, ...context }))
    .map((item) => formatNavItem({ baseUri, ...item }))
    .sort((a, b) => _get(a, "lft") - _get(b, "lft"));
};

const useGalleryNav = () => {
  const { setNavItems } = useNavData();
  const { galleryPages, baseUri } = useSitePages();

  const exhibitionGalleries = galleryPages.filter(
    (gallery) =>
      _get(gallery, "context.level") === 1 &&
      !/gallery\/?$/.test(_get(gallery, "path"))
  );

  const galleryNav = formatGallery(exhibitionGalleries, baseUri);

  useDeepCompareEffect(() => {
    setNavItems(galleryNav);
  }, [galleryNav]);
};

export default useGalleryNav;
