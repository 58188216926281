import React from "react";
import _get from "lodash.get";

import GalleryImage from "app/components/GalleryImage";

import useGalleryItemData from "app/hooks/useGalleryItemData";

const GalleryItemImage = ({ id, ...props }) => {
  const entry = useGalleryItemData(id);

  return <GalleryImage image={_get(entry, "fullImage")} {...props} />;
};

export default GalleryItemImage;
