import { css } from "@emotion/react";
import { fontSize, fontWeights, rem } from "app/styles/utils";

export { flexCenterAll, gutters, relative } from "app/styles/utils";

export const galleryGlobal = css`
  :root {
    --top-gap: 80px;
    --grid-width: 196px;
  }
`;

export const galleryGrid = css`
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-width), 1fr));
  overflow: hidden;
`;

export const buttonBox = css`
  display: block;
  width: 100%;
  height: 100%;
  padding: 16px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  border-bottom: 1px solid var(--ivory);

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    top: 16px;
    bottom: 16px;
    right: -2px;
    background-color: var(--ivory);
  }
`;

export const imageBox = css`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background-color: transparent;
  height: calc(var(--grid-width) + 2px);
  width: 100%;
  border: 0;
`;

export const pageTitle = css`
  margin-top: 0;
  ${fontSize(200, 240)};
  font-weight: ${fontWeights.normal};
`;

export const subTitle = css`
  margin-top: ${rem(100)};
  ${fontSize(65, 90)};
  font-weight: ${fontWeights.thin};
`;

export const sectionTitle = css`
  position: relative;
  padding: ${rem(20)} ${rem(40)};
  border-radius: ${rem(20)};
  margin-top: ${rem(100)};
  margin-bottom: ${rem(100)};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: var(--ivory);
    opacity: 0.08;
  }
`;

export const contentBox = css`
  grid-area: content;
`;

export const fullWidth = css`
  grid-column: 1 / -1;
`;

export const innerBox = css`
  padding-top: var(--top-gap);
`;
