import React, { useEffect } from "react";
import _get from "lodash.get";
import { navigate } from "@reach/router";

import Gallery from "app/components/Gallery";

import useSitePages from "app/hooks/useSitePages";
import useGalleryNav from "app/hooks/useGalleryNav";

const GalleryPage = ({ pageContext }) => {
  const { galleryPages, baseUri } = useSitePages();
  const pageUri = _get(pageContext, "uri");
  const firstGalleryPage = _get(galleryPages, "[0].path");
  const firstSubpageUri = _get(pageContext, "subpages[0].uri");

  useEffect(() => {
    if (/gallery\/?$/.test(pageUri)) {
      navigate(`${firstGalleryPage}/`);
    }
  }, [firstGalleryPage, pageUri]);

  useEffect(() => {
    if (firstSubpageUri) {
      navigate(`/${baseUri}/${firstSubpageUri}/`);
    }
  }, [baseUri, firstSubpageUri]);

  useGalleryNav();

  return <Gallery id={_get(pageContext, "id")} />;
};

export default GalleryPage;
