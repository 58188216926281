import { useEffect, useState, useRef } from "react";

const useIntersection = (options) => {
  const ref = useRef(null);
  const [isIntersecting, setIntersecting] = useState();
  const [intersectionRatio, setIntersectionRatio] = useState();
  // const [intersectionObserverEntry, setIntersectionObserverEntry] = useState();

  useEffect(() => {
    if (!ref.current || !typeof IntersectionObserver === "function") {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      setIntersectionRatio(entry.intersectionRatio);
    }, options);

    observer.observe(ref.current);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [options, setIntersecting, setIntersectionRatio]);

  return { ref, isIntersecting, intersectionRatio };
};

export default useIntersection;
